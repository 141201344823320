<!--用户管理-->
<template>
  <page-container title="用户管理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="账号" prop="useraccount">
              <a-input v-model.trim="queryParams.useraccount" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="username">
              <a-input v-model="queryParams.username" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="账号类型" prop="usertype">
              <a-select v-model="queryParams.usertype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in userTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账号状态" prop="status">
              <a-select v-model="queryParams.status">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="1">启用</a-select-option>
                <a-select-option :value="0">停用</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="机构" prop="userdepid">
              <a-cascader :show-search="{ filter }" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">用户列表</h3>
          <div class="table-btns">
            <a-button v-if="showTitle" type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="userid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="status" slot-scope="text" class="status-view">
            <span class="dot" :class="{'light': text=='1'}"></span>
            <span class="text">{{text=='1' ? '启用' : '未启用'}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <!-- <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button> -->
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.userid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.userid">修改</a-menu-item>
                <a-menu-item v-if="userInfo.usertype==0" :key="'password-'+record.userid">密码重置</a-menu-item>
                <a-menu-item :key="'editroles-'+record.userid">用户授权</a-menu-item>
                <a-menu-item v-if="userInfo.usertype==0" :key="'menu-'+record.userid">菜单二次授权</a-menu-item>
                <a-menu-item :key="'delete-'+record.userid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button v-if="modalType=='add'||modalType=='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="用户类型" prop="usertype">
          <a-select v-model="modalForm.usertype">
            <a-select-option v-for="(item, index) in userTypes" :key="index" :value="parseInt(item.dickey,10)">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item v-if="modalForm.usertype && modalForm.usertype == '100'" label="机构类型" prop="gathering">
          <a-select v-model="modalForm.gathering">
            <a-select-option v-for="(item, index) in gatheringTypes" :key="index" :value="item.value">{{item.title}}</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item v-if="modalForm.usertype && modalForm.usertype != '0'" label="机构" prop="userdepid">
          <a-cascader :show-search="{ filter }" :dropdownMatchSelectWidth=false :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="userDeptList?userDeptList.filter(item => item.deptype==modalForm.usertype):[]" :load-data="loadChildrenDept" placeholder="请选择机构" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="账号" prop="useraccount">
          <a-input v-model.trim="modalForm.useraccount" :disabled="modalType!='add'" />
        </a-form-model-item>
        <!-- 用来防止自动填入用户名密码 -->
        <a-form-model-item label="账号" prop="useraccount123" style="display: none;">
          <a-input v-model.trim="modalForm.useraccount123" :disabled="modalType!='add'" />
        </a-form-model-item>
        <a-form-model-item v-if="modalType=='add'" label="密码" prop="password">
          <a-input v-model.trim="modalForm.password" :type="modalForm.password.length?'password':'text'" />
        </a-form-model-item>
        <a-form-model-item v-if="modalType=='add'" label="确认密码" prop="passwordConfirm">
          <a-input v-model.trim="modalForm.passwordConfirm" :type="modalForm.passwordConfirm.length?'password':'text'" />
        </a-form-model-item>
        <a-form-model-item label="用户状态" prop="status">
          <a-radio-group v-model="modalForm.status">
            <a-radio :value="1">启用</a-radio>
            <a-radio :value="0">停用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="username">
          <a-input v-model.trim="modalForm.username" />
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-radio-group v-model="modalForm.sex">
            <a-radio :value="0">男</a-radio>
            <a-radio :value="1">女</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="手机" prop="phonenum">
          <a-input v-model.trim="modalForm.phonenum" :max-length="11" />
        </a-form-model-item>
        <a-form-model-item label="电话" prop="telnum">
          <a-input v-model.trim="modalForm.telnum" :max-length="12" />
        </a-form-model-item>
        <a-form-model-item label="身份证" prop="idnum">
          <a-input v-model.trim="modalForm.idnum" :max-length="18" />
        </a-form-model-item>
        <a-form-model-item label="电子邮件" prop="email">
          <a-input v-model.trim="modalForm.email" />
        </a-form-model-item>
        <a-form-model-item label="所属角色" prop="selectedRoles">
          <a-select mode="multiple" v-model="modalForm.selectedRoleList">
            <a-select-option v-for="item in roleList" :key="item.roleid" :value="item.roleid">{{item.rolename}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="地区" prop="areacode">
          <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="modalForm.areaList" :load-data="onLoadAreaChildren" placeholder="请选择区域" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="详细地址" prop="address">
          <a-input v-model.trim="modalForm.address" />
        </a-form-model-item>
        <a-form-model-item label="是否测试账号" prop="istest">
          <a-radio-group v-model="modalForm.istest">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="大数据视图标题名" prop="email">
          <a-input v-model.trim="modalForm.bigtitle" />
        </a-form-model-item>
        <a-form-model-item v-if="modalForm.usertype==6" label="呼叫中心账号" prop="phonename">
          <a-input v-model.trim="modalForm.phonename" />
        </a-form-model-item>
        <a-form-model-item v-if="modalForm.usertype==6" label="呼叫中心密码" prop="phonepassword">
          <a-input type="password" v-model.trim="modalForm.phonepassword" />
        </a-form-model-item>
        <a-form-model-item v-if="modalForm.usertype==6" label="呼叫中心坐席号" prop="phonextnum">
          <a-input v-model.trim="modalForm.phonextnum" />
        </a-form-model-item>
        <a-form-model-item v-if="modalForm.istest == 1" label="账号有效期" prop="expiretime">
          <a-date-picker v-model="modalForm.expiretime" />
        </a-form-model-item>
        <!-- <a-form-model-item v-if="modalForm.usertype==3||modalForm.usertype==5" label="维保组" prop="maintenancegroupid">
          <a-select v-model="modalForm.maintenancegroupid">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item v-if="modalForm.usertype==3||modalForm.usertype==5" label="维保卡号" prop="maintenancenum">
          <a-input v-model.trim="modalForm.maintenancenum" />
        </a-form-model-item>
        <template v-if="modalForm.usertype==1 || modalForm.usertype==3||modalForm.usertype==5">
          <a-form-model-item :label="modalForm.usertype==3 ? '维保作业证编号' : '司机证编号'" prop="certificate1num">
            <a-input v-model.trim="modalForm.certificate1num" />
          </a-form-model-item>
          <a-form-model-item :label="modalForm.usertype==3||modalForm.usertype==5 ? '作业证有效期' : '司机证有效期'" prop="certificate1timerange">
            <a-range-picker v-model="modalForm.certificate1timerange"></a-range-picker>
          </a-form-model-item>
          <a-form-model-item :label="modalForm.usertype==3||modalForm.usertype==5 ? '维保作业证上传' : '司机证上传'" prop="certificate1pic">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="certificate1picList" @change="safePictureUploaded">
              <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="电梯安全管理证编号" prop="certificate2num">
            <a-input v-model.trim="modalForm.certificate2num" />
          </a-form-model-item>
          <a-form-model-item label="电梯安全管理证有效期" prop="certificate2timerange">
            <a-range-picker v-model="modalForm.certificate2timerange"></a-range-picker>
          </a-form-model-item>
          <a-form-model-item label="电梯安全管理证上传" prop="certificate2pic">
            <a-upload name="fileList" :headers="uploadHeaders" :data="uploadData" accept=".jpg,.png" :before-upload="beforeUpload" action="/upload" :file-list="certificate2picList" @change="certificate2picUploaded">
              <a-button> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
        </template>
        <a-form-model-item label="公司LOGO上传" prop="logoPic">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadLogoData" accept=".jpg,.png" :before-upload="beforeUpload" action="/uploadLogo" :file-list="certificate3picList" @change="logoPicUploaded">
            <a-button> <a-icon type="upload" /> 点击上传图片 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="公司名称" prop="companyname">
          <a-input v-model="modalForm.companyname"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="modalForm.usertype==3||modalForm.usertype==5" label="上传坐标间隔(s)" prop="coordinatetime">
          <a-input v-model.trim="modalForm.coordinatetime" />
        </a-form-model-item>
        <a-form-model-item label="资质描述" prop="qualificationdesc">
          <a-textarea v-model.trim="modalForm.qualificationdesc" />
        </a-form-model-item>
        <a-form-model-item label="用户描述" prop="userdesc">
          <a-textarea v-model.trim="modalForm.userdesc" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="roleModal" title="分配角色" :bodyStyle="{minHeight: '200px', maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button @click="roleModal=false">取消</a-button>
        <a-button type="primary" @click="setRoles">确认</a-button>
      </template>
      <div class="role-modal-container">
        <!-- <a-checkbox-group v-model="checkedKeys" :options="roleOptions" /> -->
        <a-transfer :titles="['未授权', '已授权']" :render="item => item.title" :target-keys="checkedKeys" :data-source="roleOptions" @change="roleCheckedChange" />
      </div>
    </a-modal>
    <a-modal v-model="menuModal" title="菜单授权" :bodyStyle="{position: 'relative', height: '400px', overflow: 'hidden'}">
      <template slot="footer">
        <a-button @click="menuModal=false">取消</a-button>
        <a-button type="primary" @click="setMenus">确认</a-button>
      </template>
      <div class="menu-modal-container">
        <div class="box-container" style="width: calc(50% - 10px); padding: 0; border-right: solid 1px #e5e5e5;">
          <div class="box-container-inner" style="padding: 0;">
            <h3>主菜单</h3>
            <a-tree
                checkable
                checkStrictly
                v-model="mainCheckedKeys"
                :default-expand-all="false"
                :replace-fields="{children:'children', title:'menuname', key:'menuid'}"
                :tree-data="mainMenuList"
                @select="mainMenuSelect"
                @check="mainMenuCheck"
            ></a-tree>
          </div>
        </div>
      </div>
      <div class="box-container" style="position: absolute; top: 24px; right: 24px; left: 50%; padding: 0;">
        <div class="box-container-inner" style="padding: 0 0 0 15px;">
          <h3>操作菜单</h3>
          <a-checkbox-group v-model="operationCheckedKeys" :options="operationMenus" />
        </div>
      </div>
    </a-modal>
    <a-modal v-model="passwordVisible" title="修改密码">
      <template slot="footer">
        <a-button @click="passwordVisible=false">取消</a-button>
        <a-button type="primary" @click="save">确定</a-button>
      </template>
      <a-form-model ref="userForm" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
        <a-form-model-item label="用户名称" prop="username">
          <a-input v-model.trim="formData.username" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input type="password" v-model.trim="formData.password" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="passwordConfirm">
          <a-input type="password" v-model.trim="formData.passwordConfirm" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  getUserListByCondition,
  addUser,
  editUser,
  deleteUser,
  getRoleListByUserid,
  getRoleListByCondition,
  getUserByUserid,
  authRole,
  getDictByDicType,
  geteChildListByParentcode,
  getParentTreeBycode,
  getMentenacegroupListByCondition,
  getMenuListByRoleid, authMenu, modifyPersonInfo
} from 'A/xtpz.js'
import { getChildTreeListByParentId, getParentTreeByid } from 'A/jcgn.js'
import {
  getProvinceList,
  getCityList,
  getAreaList,
  getItemFromArrayByKey,
  getTreeDeepString,
  filterTreeByKey,
  getCache
} from 'U'
import store from '@/store'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import {authUserMenu, authUserMenus, getMenuListByUserid, getUserRoleByUserid} from "A/xtpz";
export default {
  name: 'peopleManagement',
  props: {
    showTitle: {
      default: true
    },
    userDepId: {
      default: null
    }
  },
  mixins: [deptselect],
  data() {
    return {
      passwordVisible:false,
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      userTypes: [],
      gatheringTypes: [
        {
          title: '监管类',
          value: '5'
        },
        {
          title: '维保类',
          value: '6'
        }
      ],
      userDeptList: [],
      areaOptions: [],
      roleList: [],
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '用户管理',
          path: ''
        },
      ],
      queryParams: {
        useraccount: '',
        username: '',
        usertype: '',
        status: '',
        userdepid: ''
      },
      tableColumns: [
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount'
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username'
        },
        {
          title: '角色',
          dataIndex: 'rolename',
          key: 'rolename',
        },
        {
          title: '所属机构',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '电子邮件',
          dataIndex: 'email',
          key: 'email'
        },
        {
          title: '用户状态',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => a.status - b.status,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        userid: '',
        usertype: '',
        // gathering: '',
        userdepid: '',
        selectDeptList: [],
        useraccount: '',
        useraccount123: '',
        password: '',
        passwordConfirm: '',
        status: 1,
        username: '',
        sex: 0,
        phonenum: '',
        idnum: '',
        telnum: '',
        email: '',
        selectedRoles: '',
        selectedRoleList: [],
        areacode: '',
        areaList: [],
        address: '',
        istest: 0,
        expiretime: null,
        maintenancegroupid: '0',
        maintenancenum: '',
        certificate1num: '',
        certificate1timerange: null,
        certificate1starttime: '',
        certificate1endtime: '',
        certificate1pic: '',
        certificate2num: '',
        certificate2timerange: null,
        certificate2starttime: '',
        certificate2endtime: '',
        certificate2pic: '',
        coordinatetime: '',
        qualificationdesc: '',
        userdesc: '',
        bigtitle:'',
        phonename:'',
        phonepassword:'',
        phonextnum:'',
        companyname:'',
        logoUrl:'',
      },
      modalRules: {
        usertype: [{required: true, message: '请选择用户类型'}],
        // gathering: [{required: true, message: '请选择机构类型'}],
        userdepid: [{required: true, message: '请选择机构'}],
        useraccount: [
          {required: true, message: '请输入账号'},
          {
            validator: (rule, value, callback) => {
              if(/^[\x21-\x7E]+$/.test(value)) {
                callback();
              }else {
                callback('账号格式错误')
              }
            }, trigger: 'blur'
          }
        ],
        username: [{required: true, message: '请输入姓名'}],
        password: [
          {required: true, message: '请输入密码'},
          {
            validator: (rule, value, callback) => {
              if(/^[\x21-\x7E]{8,}$(?<!^(\d+|[a-z]+|[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+)$)/i.test(value)) {
                callback();
              }else {
                callback('密码最小长度为8，必须包含数字、字母、特殊字符中两种以上字符')
              }
            }, trigger: 'blur'
          }
        ],
        passwordConfirm: [
          {required: true, message: '请确认密码'},
          {
            validator: (rule, value, callback) => {
              if(value == this.modalForm.password) {
                callback();
              }else {
                callback('两次输入的密码不一致')
              }
            }, trigger: 'blur'
          }
        ],
        phonenum: [
          {required: true, message: '请收入手机号码'},
          {
            validator: (rule, value, callback) => {
              if(/^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
        idnum: [
          {
            validator: (rule, value, callback) => {
              if(!value||/^\d{17}[\dx]$/i.test(value)) {
                callback();
              }else {
                callback('请输入正确的身份证号')
              }
            }, trigger: 'blur'
          }
        ],
        telnum: [
          {
            validator: (rule, value, callback) => {
              if(!value||/^\d{7,12}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的电话号码')
              }
            }, trigger: 'blur'
          }
        ],
        selectedRoles: [{required: true, message: '请选择角色'}],
        areacode: [{required: true, message: '请选择行政区域'}],
        expiretime: [{required: true, message: '请选择账号有效期'}],
      },
      selectedUser: null,
      roleModal: false,
      roleOptions: [],
      checkedKeys: [],
      certificate1picList: [],
      certificate2picList: [],
      certificate3picList: [],
      modifyUserdeptid: '',
      userdepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      counts:'',
      menuModal: false,
      mainCheckedKeys: {
        checked: [],
        halfChecked: [],
      },
      treeDisabledArr:[1000],
      operationCheckedKeys: [],
      operationCheckedMap: {},
      operationMenus: [],
      usertype:null,
      formData: {
        admin:'true',
        userid: '',
        username: '',
        password: '',
        passwordConfirm: '',
        usertype:null,
        userdepid:null,
        playtype:null,
      },
      formRules: {
        username: [{required: true, message: '请输入账户'}],
        passwordConfirm: [
          {
            validator: (rule, value, callback) => {
              if(value == this.formData.password) {
                callback();
              }else {
                callback('两次输入的密码不一致')
              }
            }, trigger: 'blur'
          }
        ],
      },
      uploadLogoData:{
        folder:'logo',
        username:'admin',
        filetype:'jpg/png'
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      menuList: state => state.menuList
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      let title = '';
      if(this.modalType == 'add') {
        title = '新增';
      }else if(this.modalType == 'detail') {
        title = '详情';
      }else if(this.modalType == 'edit') {
        title = '修改';
      }else {
        title = '';
      }
      return title;
    },
    mainMenuList() {
      return filterTreeByKey(JSON.parse(JSON.stringify(this.menuList)), 'menutype', 1);
    },
    certificate1starttime() {
      if(this.modalForm.certificate1timerange && this.modalForm.certificate1timerange[0]) {
        return moment(this.modalForm.certificate1timerange[0]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
    certificate1endtime() {
      if(this.modalForm.certificate1timerange && this.modalForm.certificate1timerange[1]) {
        return moment(this.modalForm.certificate1timerange[1]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
    certificate2starttime() {
      if(this.modalForm.certificate2timerange && this.modalForm.certificate2timerange[0]) {
        return moment(this.modalForm.certificate2timerange[0]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
    certificate2endtime() {
      if(this.modalForm.certificate2timerange && this.modalForm.certificate2timerange[1]) {
        return moment(this.modalForm.certificate2timerange[1]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
  },
  watch: {
    modalVisible(val) {
      if(!val) {
        this.resetModal();
      }
    },
    'modalForm.usertype'(val) {
      // if(val == 100) {
      //   // 采集员
      //   this.modalForm.selectDeptList = [];
      //   this.userDeptList = [];
      //   return;
      // }
      // this.modalForm.gathering = '';
      if(val || val===0) {
        let deptype = val;
        // if(val=='0') {
        //   deptype = ''
        // }else if(val=='4' || val=='5') {
        //   deptype = '2'
        // }else if(val=='6') {
        //   deptype = '3'
        // }
        this.getUserDeptList(deptype)
      }
    },
    // 'modalForm.gathering'(val) {
    //   if(val) {
    //     let deptype = val;
    //     if(val=='5') {
    //       deptype = '2'
    //     }else if(val=='6') {
    //       deptype = '3'
    //     }
    //     this.getUserDeptList(deptype)
    //   }
    // },
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
        this.maintenancegroupidOptions = [];
      }
    },
    'modalForm.userdepid'(val) {
      if(val) {
        if(typeof val == 'number') {
          val = val.toString();
        }
        this.modalForm.selectDeptList = val.split('|')
      }else {
        this.modalForm.selectDeptList = []
      }
    },
    'modalForm.areaList'(val) {
      if(val && val.length) {
        this.modalForm.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode');
      }else {
        this.modalForm.areacode = '';
      }
    },
    'modalForm.selectedRoleList'(val) {
      if(val && val.length) {
        this.modalForm.selectedRoles = val.join('|')
        this.$refs.modalForm.clearValidate('selectedRoles');
      }else {
        this.modalForm.selectedRoles = ''
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    userDepId(){
      this.queryParams.userdepid=this.userDepId
      this.getTableData()
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    filter(inputValue, path) {
      return path.some(option => option.userdepname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    init() {
      if(!this.showTitle){
        this.breadcrumb=[]
      }
      this.getUserTypes();
      this.getTableData();
      this.initAreaOptions();
    },
    initMaintenancegroupidOptions() {
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {
        maintenancedepid: userdepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    getUserTypes() {
      let params = {
        dictype: '11'
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          if(this.userInfo.usertype == '0') {
            if(this.userInfo.useraccount == 'admin') {
              this.userTypes = res.item;
            }else {
              this.userTypes = res.item.filter(item => item.dickey != '0');
            }
          }else {
            this.userTypes = res.item.filter(item => item.dickey == this.userInfo.usertype);
          }
          this.getRoleListByUserTypes();
        }
      })
    },
    getUserDeptList(deptype='') {
      this.modalForm.selectDeptList = [];
      this.userDeptList = [];
      this.userDeptList = this.getChildTreeListByParentId('', deptype);
    },
    getChildTreeListByParentId(parentid, deptype) {
      let params = {
        parentid,
        deptype,
      }
      getChildTreeListByParentId(params).then(res => {
        if(res && res.returncode == '0') {
          this.userDeptList = res.item.map(item => {
            item.userdepid = item.userdepid.toString();
            item.isLeaf = item.childsize === 0;
            return item;
          });
          if(this.modifyUserdeptid) {
            this.initUserDeptid(this.modifyUserdeptid)
            this.modifyUserdeptid = '';
          }
        }
      })
    },
    initUserDeptid(userdepid) {
      let params = {
        userdepid
      }
      getParentTreeByid(params).then(res => {
        if(res && res.returncode == '0') {
          let deepUserdepid = getTreeDeepString(res.item, '', 'userdepid', 'childUserdep');
          let deepUserdepList = deepUserdepid.split('|');
          if(deepUserdepList.length > 1) {
            this.initUserDeptListTree(deepUserdepList, 0, deepUserdepList);
          }else {
            this.modalForm.selectDeptList = deepUserdepList;
          }
        }
      })
    },
    initUserDeptListTree(arr, index, deepUserdepList) {
      if(index < arr.length-1) {
        let parentid = arr[index];
        let targetOption = getItemFromArrayByKey(this.userDeptList, 'userdepid', parentid);
        let params = {
          parentid
        }
        getChildTreeListByParentId(params).then(res => {
          // targetOption.loading = false;
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.userdepid = item.userdepid.toString();
              item.isLeaf = item.childsize === 0;
              return item;
            });
            // this.userDeptList = [...this.userDeptList]
            this.initUserDeptListTree(arr, index+1, deepUserdepList)
          }
        }).catch(err => {
          // targetOption.loading = false;
        })
      }else {
        this.modalForm.selectDeptList = deepUserdepList;
      }
    },
    loadChildrenDept(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getUserDeptChildDept(targetOption.userdepid);
    },
    getUserDeptChildDept(parentid) {
      let targetOption = getItemFromArrayByKey(this.userDeptList, 'userdepid', parentid);
      let params = {
        parentid
      }
      getChildTreeListByParentId(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.userdepid = item.userdepid.toString();
            item.isLeaf = item.childsize === 0;
            return item;
          });
          this.userDeptList = [...this.userDeptList]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    initAreaOptions(parentcode='') {
      let params = {
        parentcode
      };
      geteChildListByParentcode(params).then(res => {
        if(res && res.returncode == '0') {
          this.areaOptions = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
        }
      })
    },
    onLoadAreaChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildArea(targetOption.areacode);
    },
    getChildArea(parentcode) {
      let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
      let params = {
        parentcode
      }
      geteChildListByParentcode(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
          this.areaOptions = [...this.areaOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    initAreaTreeOptions() {
      if(this.modalForm.areacode) {
        let params = {
          areacode: this.modalForm.areacode
        }
        getParentTreeBycode(params).then(res => {
          if(res && res.returncode == '0') {
            let areacodeTreeStr = getTreeDeepString(res.item, '', 'areacode', 'childArea');
            // console.log(areacodeTreeStr)
            let deepAreaList = areacodeTreeStr.split('|');
            if(deepAreaList.length > 1) {
              this.initAreaListTree(deepAreaList, 0);
            }else {
              this.modalForm.areaList = deepAreaList;
            }
          }
        })
      }
    },
    initAreaListTree(deepAreaList, index) {
      if(index < deepAreaList.length-1) {
        let parentcode = deepAreaList[index];
        let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
        let params = {
          parentcode
        }
        geteChildListByParentcode(params).then(res => {
          // targetOption.loading = false;
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              return item;
            });
            // this.areaOptions = [...this.areaOptions]
            this.initAreaListTree(deepAreaList, index+1)
          }
        }).catch(err => {
          // targetOption.loading = false;
        })
      }else {
        this.modalForm.areaList = deepAreaList;
      }
    },
    getRoleListByUserid() {
      getRoleListByUserid({
        userid: this.userInfo.userid
      }).then(res => {
        if(res && res.returncode == '0') {
          this.roleList = res.item;
          this.roleOptions = res.item.map(item => {
            return {
              key: item.roleid+'',
              title: item.rolename
            }
          })
        }
      })
    },
    getRoleListByUserTypes() {
      let roletype = this.userTypes.map(item => item.dickey).join('|');
      getRoleListByCondition({
        roletype
      }).then(res => {
        if(res && res.returncode == '0') {
          this.roleList = res.item;
          this.roleOptions = res.item.map(item => {
            return {
              key: item.roleid+'',
              title: item.rolename
            }
          })
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.getTableData()
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getUserListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if(type == 'add') {
        this.modalVisible = true;
        this.$nextTick(() => {
          this.resetModal();
        })
      }else {
        this.modalVisible = true;
        this.showLoading();
        this.$nextTick(() => {
          let params = {
            userid: record.userid
          }
          getUserByUserid(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              record = res.item;
              if(record.areaid) {
                this.modalForm.areacode = record.areaid;
                this.initAreaTreeOptions();
              }
              this.modalForm.userid = record.userid;
              // this.modalForm.userdepid = record.userdepid;
              this.modifyUserdeptid = record.userdepid;
              // if(record.usertype == '5' || record.usertype == '6') {
              //   // 采集员
              //   this.modalForm.usertype = 100;
              //   this.modalForm.gathering = record.usertype.toString();
              // }else {
                this.modalForm.usertype = record.usertype;
                // this.modalForm.gathering = '';
              // }
              this.modalForm.useraccount = record.useraccount;
              this.modalForm.status = record.status;
              this.modalForm.username = record.username;
              this.modalForm.sex = record.sex;
              this.modalForm.phonenum = record.phonenum;
              this.modalForm.idnum = record.idnum;
              this.modalForm.telnum = record.telnum;
              this.modalForm.email = record.email;
              this.modalForm.selectedRoleList = record.roleList ? record.roleList.map(item => item.roleid) : [];
              this.modalForm.address = record.address;
              this.modalForm.istest = record.istest;
              this.modalForm.expiretime = record.expiretime ? moment(record.expiretime, 'YYYYMMDD') : null;
              this.modalForm.maintenancegroupid = record.maintenancegroupid;
              this.modalForm.maintenancenum = record.maintenancenum;
              this.modalForm.certificate1num = record.certificate1num;
              this.modalForm.certificate1timerange = null;
              this.modalForm.phonextnum=record.phonextnum;
              this.modalForm.phonename=record.phonename;
              this.modalForm.phonepassword=record.phonepassword;

              this.modalForm.companyname=record.companyname
              this.uploadLogoData.username=this.modalForm.useraccount+"-logo";
              if(record.logourl) {
                let nameIndex = record.logourl.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = record.logourl.lastIndexOf('\\');
                }
                let fileName = record.logourl.substr(nameIndex+1);
                this.certificate3picList = [{
                  uid: 'certificate3picuid',
                  name: fileName,
                  status: 'done',
                  url: record.logourl,
                }];
              }

              if(record.certificate1starttime && record.certificate1endtime) {
                this.modalForm.certificate1timerange = [moment(record.certificate1starttime, 'YYYYMMDD'),moment(record.certificate1endtime, 'YYYYMMDD')]
              }
              this.modalForm.certificate1pic = record.certificate1pic;
              if(record.certificate1pic) {
                let nameIndex = record.certificate1pic.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = record.certificate1pic.lastIndexOf('\\');
                }
                let fileName = record.certificate1pic.substr(nameIndex+1);
                this.certificate1picList = [{
                  uid: 'certificate1picuid',
                  name: fileName,
                  status: 'done',
                  url: record.certificate1pic,
                }];
              }
              this.modalForm.certificate2num = record.certificate2num;
              this.modalForm.certificate2timerange = null;
              if(record.certificate2starttime && record.certificate2endtime) {
                this.modalForm.certificate2timerange = [moment(record.certificate2starttime, 'YYYYMMDD'),moment(record.certificate2endtime, 'YYYYMMDD')]
              }
              this.modalForm.certificate2pic = record.certificate2pic;
              if(record.certificate2pic) {
                let nameIndex = record.certificate2pic.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = record.certificate2pic.lastIndexOf('\\');
                }
                let fileName = record.certificate2pic.substr(nameIndex+1);
                this.certificate2picList = [{
                  uid: 'certificate2picuid',
                  name: fileName,
                  status: 'done',
                  url: record.certificate2pic,
                }];
              }
              this.modalForm.coordinatetime = record.coordinatetime;
              this.modalForm.qualificationdesc = record.qualificationdesc;
              this.modalForm.userdesc = record.userdesc;
              this.modalForm.bigtitle = record.bigtitle;
            }else {
              this.$message.error(res.errormsg||'查询失败');
            }
          })
        })
      }
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.userid);
        },
        onCancel() {},
      });
    },
    delete(userid) {
      if(userid) {
        let params = {
          userid
        };
        deleteUser(params).then(res => {
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if(this.modalType == 'add' || this.modalType == 'edit') {
        if(this.modalForm.areaList && this.modalForm.areaList.length) {
          this.modalForm.areacode = this.modalForm.areaList[this.modalForm.areaList.length-1];
        }
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.addOrEdit();
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    addOrEdit() {
      this.showLoading();
      let usertype = this.modalForm.usertype;
      // if(usertype == '100') {
      //   usertype = this.modalForm.gathering;
      // }
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let expiretime = '';
      if(this.modalForm.expiretime) {
        expiretime = moment(this.modalForm.expiretime).format('YYYYMMDD');
      }
      let params = {
        usertype: usertype,
        useraccount: this.modalForm.useraccount,
        status: this.modalForm.status,
        username: this.modalForm.username,
        sex: this.modalForm.sex,
        phonenum: this.modalForm.phonenum,
        idnum: this.modalForm.idnum,
        telnum: this.modalForm.telnum,
        email: this.modalForm.email,
        roles: this.modalForm.selectedRoleList.join('|'),
        userdepid,
        areaid: this.modalForm.areacode,
        address: this.modalForm.address,
        istest: this.modalForm.istest,
        expiretime: expiretime,
        maintenancegroupid: this.modalForm.maintenancegroupid,
        maintenancenum: this.modalForm.maintenancenum,
        certificate1num: this.modalForm.certificate1num,
        certificate1starttime: this.certificate1starttime,
        certificate1endtime: this.certificate1endtime,
        certificate1pic: this.modalForm.certificate1pic,
        certificate2num: this.modalForm.certificate2num,
        certificate2starttime: this.certificate2starttime,
        certificate2endtime: this.certificate2endtime,
        certificate2pic: this.modalForm.certificate2pic,
        coordinatetime: this.modalForm.coordinatetime,
        qualificationdesc: this.modalForm.qualificationdesc,
        userdesc: this.modalForm.userdesc,
        bigtitle: this.modalForm.bigtitle,
        phonename: this.modalForm.phonename,
        phonepassword: this.modalForm.phonepassword,
        phonextnum: this.modalForm.phonextnum,
        logourl:this.modalForm.logoUrl,
        companyname:this.modalForm.companyname
      };
      if(this.modalType == 'add') {
        params.password = this.modalForm.password
        addUser(params).then(this.addOrEditResult);
      }else if(this.modalType == 'edit') {
        params.userid = this.modalForm.userid;
        editUser(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if(res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      }else {
        this.$message.error(res.errormsg||'操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
      this.modalForm.selectedRoleList = [];
      this.modalForm.areaList = [];
      this.certificate1picList = [];
      this.certificate2picList = [];
      this.certificate3picList = [];
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let userid = arr[1];
      this.selectedUser = getItemFromArrayByKey(this.tableData, 'userid', userid);
      if('editroles' == type) {
        this.showLoading();
        let params = {
          userid: userid
        }
        getUserByUserid(params).then(res => {
          if(res && res.returncode == '0') {
            this.checkedKeys = res.item.roleList.map(item => item.roleid+'')
          }
          this.hideLoading();
        })
        this.roleModal = true;
      }else if('detail' == type){
        this.showModal('detail', userid, this.selectedUser)
      }else if('edit' == type){
        this.showModal('edit', userid, this.selectedUser)
      }else if('delete' == type){
        this.deleteConfirm(userid, this.selectedUser)
      } else if(type == 'menu') {
        this.getUserRole(this.selectedUser.userid);
        this.userid=this.selectedUser.userid;
      }else if(type == 'password') {
        this.formData.userid=this.selectedUser.userid
        this.formData.playtype=this.selectedUser.playtype
        this.formData.userdepid=this.selectedUser.userdepid
        this.formData.username=this.selectedUser.username
        this.formData.usertype=this.selectedUser.usertype
        this.passwordVisible=true
      }
    },
    save() {
      this.$refs.userForm.validate(valid => {
        if(valid) {
          this.updateUserInfo();
        }else {
          return false;
        }
      })
    },
    updateUserInfo() {
      this.showLoading();
      let params = {...this.formData};
      modifyPersonInfo(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          this.passwordVisible=false;
          // this.$store.commit('setUserInfo', {
          //   ...this.userInfo,
          //   usertype: this.formData.usertype,
          //   userdepid: this.formData.userdepid,
          //   playtype: this.formData.playtype,
          //   username: this.formData.username,
          // })
        }else {
          this.$message.error(res.errormsg||'操作失败')
        }
      })
    },
    getUserRole(userid){
      let params={
        userid:userid
      }
      getUserRoleByUserid(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.roleid=res.item.item[0].roleid
          this.authMenuModal(this.roleid,this.selectedUser.userid)
        }
      })
    },
    authMenuModal(roleid,userid) {
      this.mainCheckedKeys.checked = [];
      this.showLoading();
      let params = {
        roleid: roleid,
        userid:userid
      }
      getMenuListByRoleid(params).then(res => {
        if(res && res.returncode == '0') {
          if(res.item.length==0){
            let params = {
              // roleid: roleid,
              userid:userid,
            }
            getMenuListByUserid(params).then(res => {
              if(res && res.returncode == '0') {
                this.checkedKeys = res.item;
                for(let i=0;i<res.item.length;i++){
                  for(let j=0;j<res.item[i].children.length;j++){
                    this.checkedKeys.push(res.item[i].children[j])
                  }
                }
                res.item.filter(item => item.menutype==1).map(item => item.menuid).forEach(item => {
                  if(this.mainCheckedKeys.checked.indexOf(item) < 0) {
                    this.mainCheckedKeys.checked.push(item);
                  }
                })
                this.initOpeartionCheckedMap();
              }
            })
          }else{
            this.checkedKeys = res.item;
            res.item.filter(item => item.menutype==1).map(item => item.menuid).forEach(item => {
              if(this.mainCheckedKeys.checked.indexOf(item) < 0) {
                this.mainCheckedKeys.checked.push(item);
              }
            })
            this.initOpeartionCheckedMap();
          }
        }
        this.hideLoading();
      })
      this.menuModal = true;
    },
    initOpeartionCheckedMap() {
      let operationMenus = this.checkedKeys.filter(item => item.menutype == 2);
      operationMenus.forEach(item => {
        if(!this.operationCheckedMap[item.parentmenuid]) {
          this.operationCheckedMap[item.parentmenuid] = [];
        }
        if(this.operationCheckedMap[item.parentmenuid].indexOf(item.menuid) < 0) {
          this.operationCheckedMap[item.parentmenuid].push(item.menuid);
        }
      })
    },
    mainMenuSelect(selectedKeys, e) {
      if(e.selected) {
        // 选中
        let checkedMenuid = selectedKeys[selectedKeys.length-1];
        this.getOperationMenus(checkedMenuid);
      }else {
        // 取消选中
        this.getOperationMenus();
      }
    },
    mainMenuCheck(checkedKeys, e) {
      // checkbox选中
      if(e.checked) {
        // 选中
        let checkedMenuid = e.node.dataRef.menuid;
        this.selectAllCascaderMenus(checkedMenuid, true);
      }else {
        // 取消选中
        let checkedMenuid = e.node.dataRef.menuid;
        this.selectAllCascaderMenus(checkedMenuid);
      }
    },
    selectAllCascaderMenus(checkedMenuid, checked) {
      // 查询所有相关菜单(1.所有父级菜单 2.所有子集菜单 3.所有操作菜单)
      if(checked) {
        // 所有父菜单跟随选中
        let allParents = this.getAllParentMenus(this.menuList, checkedMenuid, []);
        // console.log(allParents)
        allParents.forEach(item => {
          if(item.menutype == 1 && this.mainCheckedKeys.checked.indexOf(item.menuid) < 0) {
            this.mainCheckedKeys.checked.push(item.menuid);
          }
        })
      }else {
        let idIndex = this.mainCheckedKeys.checked.indexOf(checkedMenuid)
        if(idIndex > -1) {
          this.mainCheckedKeys.checked.splice(idIndex, 1);
        }
      }
      // 所有子菜单跟随选中
      let checkedMenu = getItemFromArrayByKey(this.menuList, 'menuid', checkedMenuid);
      if(checkedMenu.children && checkedMenu.children.length) {
        let allChildMenus = this.getAllChildMenus(checkedMenu.children, []);
        if(checked) {
          allChildMenus.forEach(item => {
            if(item.menutype == 1 && this.mainCheckedKeys.checked.indexOf(item.menuid) < 0) {
              this.mainCheckedKeys.checked.push(item.menuid);
            }else if(item.menutype == 2){
              if(!this.operationCheckedMap[item.parentmenuid]) {
                this.operationCheckedMap[item.parentmenuid] = [];
              }
              if(this.operationCheckedMap[item.parentmenuid].indexOf(item.menuid) < 0) {
                this.operationCheckedMap[item.parentmenuid].push(item.menuid);
              }
              if(this.mainMenu && this.mainMenu.menuid == item.parentmenuid) {
                // 当前选中菜单的操作菜单列表
                if(this.operationCheckedKeys.indexOf(item.menuid) < 0) {
                  this.operationCheckedKeys.push(item.menuid);
                }
              }
            }
          })
        }else {
          allChildMenus.forEach(item => {
            if(item.menutype == 1) {
              let idIndex = this.mainCheckedKeys.checked.indexOf(item.menuid)
              if(idIndex > -1) {
                this.mainCheckedKeys.checked.splice(idIndex, 1);
              }
            }else if(item.menutype == 2){
              if(this.operationCheckedMap[item.parentmenuid] && this.operationCheckedMap[item.parentmenuid].length) {
                let idIndex = this.operationCheckedMap[item.parentmenuid].indexOf(item.menuid);
                if(idIndex > -1) {
                  this.operationCheckedMap[item.parentmenuid].splice(idIndex, 1);
                }
              }
              if(this.mainMenu && this.mainMenu.menuid == item.parentmenuid) {
                // 当前选中菜单的操作菜单列表
                let opIndex = this.operationCheckedKeys.indexOf(item.menuid);
                if(opIndex > -1) {
                  this.operationCheckedKeys.splice(opIndex, 1);
                }
              }
            }
          })
        }
      }
    },
    getAllParentMenus(arr, checkedMenuid, result) {
      // mainCheckedKeys
      let newRes = [...result];
      for(let i=0; i<arr.length; i++) {
        let menu = arr[i];
        newRes.push(menu);
        if(menu.menuid == checkedMenuid) {
          break;
        }else if(menu.children) {
          let childArr = this.getAllParentMenus(menu.children, checkedMenuid, newRes)
          if(childArr.length > newRes.length) {
            newRes = childArr;
            break;
          }else {
            newRes = [...result];
          }
        }else {
          newRes = [...result];
        }
      }
      return newRes;
    },
    getAllChildMenus(arr, result) {
      arr.forEach(item => {
        result.push(item);
        if(item.children) {
          this.getAllChildMenus(item.children, result);
        }
      })
      return result;
    },
    setMenus() {
      let userids;
      let useridss=[];
      let param={
        userid:this.userid
      }
      getUserRoleByUserid(param).then(res=>{
        if(res && res.returncode == '0') {
          // console.log(res.item.item[0])
          // userids=(res.item.item[0].userids).toString()
          // console.log(userids)
          // useridss=userids.split(',')
          // console.log(useridss.indexOf(this.userid.toString()))
          // if(useridss.indexOf(this.userid.toString())>-1){
          // }else{
          //   userids=userids+","+this.userid
          // }
          // console.log(userids)
          let checkedKeys = [...this.mainCheckedKeys.checked];
          this.mainCheckedKeys.checked.forEach(item => {
            if(this.operationCheckedMap[item] && this.operationCheckedMap[item].length) {
              checkedKeys = [...checkedKeys, ...this.operationCheckedMap[item]];
            }
          })
          let params = {
            roleid: this.roleid,
            menuids: checkedKeys.join('|'),
            userid: this.userid,
          }
          this.showLoading();
          authUserMenus(params).then(res => {
            if(res && res.returncode == '0') {
              this.hideLoading();
              this.$message.success('操作成功');
              this.menuModal = false;
            }else {
              this.hideLoading();
              this.$message.error(res.errormsg||'操作失败');
            }
          })
        }
      })
    },
    roleCheckedChange(targetKeys) {
      this.checkedKeys = targetKeys;
    },
    setRoles() {
      let params = {
        userid: this.selectedUser.userid,
        roleids: this.checkedKeys.join('|')
      };
      authRole(params).then(res => {
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          this.roleModal = false;
          this.checkedKeys = [];
          this.getTableData();
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      })
    },
    safePictureUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          this.modalForm.certificate1pic = file.response.urlPath;
        }
        return file;
      });

      this.certificate1picList = fileList;
    },
    certificate2picUploaded(info) {
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.modalForm.certificate2pic = file.response.urlPath;
        }
        return file;
      });
      this.certificate2picList = fileList;
    },
    logoPicUploaded(info){
      let fileList = [...info.fileList];
      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.modalForm.logoUrl = file.response.urlPath;
        }
        return file;
      });
      this.certificate3picList = fileList;
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error('上传的文件类型只能是.jpg或者.png图片类型!');
        return false
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!');
        return false
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
</style>